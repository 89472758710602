<template>
  <v-container fluid fill-height id="notFound404">
    <v-row class="pa-12" justify="center">
      <h1 class="text-center mb-12">404! Page Not Found :(</h1>
      <v-img
        class="mx-auto"
        style="max-height: 30vh"
        :src="require(`../assets/landing/undraw_searching_p5ux.svg`)"
        contain
      ></v-img>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound404",
  metaInfo: {
    title: "404 Not Found",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "404 Page Not Found.",
      },
      {
        vmid: "robots",
        name: "robots",
        content: "noindex, nofollow",
      },
    ],
  },
};
</script>
